import {
  createBaseThemeOptions,
  pageTheme as defaultPageThemes,
  PageTheme,
  palettes,
  createUnifiedTheme,
} from '@backstage/theme';

const pageThemesOverride: Record<string, PageTheme> = {};

const linearGradient = 'linear-gradient(120deg,#ff0097,#fe791a)'

Object.keys(defaultPageThemes).map(key => {
  pageThemesOverride[key] = {
    ...defaultPageThemes[key],
    fontColor: '#000000',
    backgroundImage: linearGradient
  };
});

export const AuraTheme = createUnifiedTheme({
  ...createBaseThemeOptions({
    palette: {
      ...palettes.light,
      primary: {
        main: '#d90078',
        light: '#FF66C1',
        dark: '#d90078',
      },
      navigation: {
        ...palettes.light.navigation,
        selectedColor: "#d90078",
        background: '#D9D9D9',
        color: '#232323',
        indicator: '#d90078',
        navItem: {
          hoverBackground: linearGradient,
        },
      }
    },
  }),
  fontFamily: 'IBM Plex Sans,Helvetica,sans-serif',
  pageTheme: pageThemesOverride,
  defaultPageTheme: 'home',
});
